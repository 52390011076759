.container {
    width: 100%;
    /* padding: 20px; */
    /* background-color: #2A2A2A; */
    color: white;
    padding: 3rem 0px;
  }
  
  .headerSection {
    text-align: center;
    padding: 20px;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
  }
  .sectionTitle{
    text-align: center;
    font-size: 2.5rem;
    width: 70%;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 1rem;
  }
  .healthcareSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
    width: 90%;
    margin: auto;
  }
  
  .healthcareContent {
    flex: 1;
    padding: 20px;
  }
  
  .healthcareImage {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .overlay {
    position: absolute;
    top: 50%; /* Adjust to position the content */
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    /* padding: 20px; */
    text-align: left;
    border-radius: 10px;
    width: 90%;
    height: 18rem;
    display: flex;
    justify-content: baseline;
    align-items: flex-end;
    overflow: hidden;
  }

  .contant{
    align-items: baseline;
    display: grid;
    align-items: end;
    gap: 0px;
    padding: 1.5rem;
  }

  .firstLay{
    margin: 0px;
    
  }
  
  .responsiveImage {
    max-width: 90.8%;
    height: 18.23rem;
  }
  
  .partnersSection {
    padding: 20px 0;
    /* text-align: center; */
  }

  .sectionText,.sectionText2{
    font-size: .9rem;
    line-height: 1.7rem;
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;
    text-align: left;
  }
  .sectionText2{
    text-align: center;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    margin: 20px .4rem;
    border: 1px solid #fff;
    /* max-width: 500px; */
    max-width: 650px;
    border-radius: 8px;
    padding:0.7rem 0.2rem;
    margin: auto;

  }
  
  .tabButton {
    background: #2A2A2A;
    /* border: 1px solid white; */
    /* padding: 10px; */
    cursor: pointer;
    color: white;
    border: none;
    padding: .7rem;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
  }
  
  .activeTab {
    background-color: white;
    color: #2E2E2E;
  }
  
  .cardsContainer {
    display: flex;
    /* display: grid;
    grid-template-columns: repeat(4, 1fr); */
    gap: 20px;
    cursor: grab;
    user-select: none;
    /* overflow-x: auto; */
    padding: 1rem;
  }
  
  .card {
    background: #3C3C3C;
    padding: 20px;
    border-radius: 10px;
    min-width: 250px;
    max-width: 300px;
  }
  
  .cardImage {
    /* width: 50px; */
    height: 50px;
    object-fit: contain;
  }

  #idcard{
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .join{
    color: #252525;
    background-color: #fff;
    /* border: ; */
    border-radius: 32px;
    border: none;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: -3rem;
  }
  
  .cardTitle {
    font-size: 1.2rem;
  }
  
  .cardText {
    font-size: 1rem;
  }

  @media (max-width:1000px) {
    .responsiveImage{
        height: 13rem;
    }
    .overlay{
        height: 13.1rem;
    }
    .cardsContainer{
        grid-template-columns: repeat(3, 1fr);
    }
  }

  .care{
    color: #717171;
  }
  
  .health{
    font-size: 1.8rem;
  }

  .imageText{
    font-size: 1.2rem;
    line-height: 1.6rem;
    width: 75%;
  }

  .overlay_button{
    background-color: #fff;
    color: #2E2E2E;
    padding: 1rem;
    border: none;
    border-radius: 1.2rem;
    max-width: 13rem;
    font-size: 1rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .cardsContainer{
        grid-template-columns: repeat(2, 1fr);
    }
    .healthcareSection {
      flex-direction: column;
    }
    .tabs{
        display: grid;
        width: 90%;
    }
    
    .healthcareImage {
      order: 1;
      margin-bottom: 1rem;
    }
   
    .healthcareContent {
      order: 2;
      width: 100%;
      margin: 0px;
      padding: 0px;
    }
    
    .cardsContainer {
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
    .sectionTitle{
        font-size: 1.2rem;
        width: 90%;
        margin-top: 2rem;
    }
    .sectionText,.sectionText2{
        width: 90%;
        margin-bottom: 1rem;
    }
  }
  

  @media (max-width:500px) {
    .cardsContainer{
        grid-template-columns: repeat(1, 1fr);
    }
    .cardTitle{
      font-size: 1rem;
    }
    .cardText{
      font-size: .8rem;
    }
    .imageText{
      font-size: 0.8rem;
      line-height: 1rem;
      width: 100%;
    }
    .container{
      padding: 1rem 0;
    }
  }