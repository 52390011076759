*{
  font-family: Inter;
}

   .container {
    margin: auto;
    padding: 40px 40px;
    text-align: center;
    /* background: url("/images/background.png") no-repeat center center/cover; */
    color: white;
    min-height: 100vh;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .title{ 
    font-size: 16px;
  }
  .tab,.activeTab{
    background: #292929;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 38px;
    color: white;
    border: 2px solid #fff;
    transition: 0.3s ease;
    width: 33%;
    font-weight: bold;
  }

  .leftalign{
    text-align: left;
  }
  
  .activeTab,.role {
    background: white;
    color: black;
    /* font-weight: bold; */
    
  }
  
  .tabDescription {
    margin-bottom: 30px;
    font-size: 18px;
  }
  
  .cardsContainer {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(330px, 1fr)); */
    grid-template-columns: repeat(3, 1fr);
    gap: 66px;
    justify-items: center;
    /* padding-bottom: 40px; */
  }
  
  .card {
    background: #3C3C3C;
    padding: 20px;
    border-radius: 15px;
    position: relative;
    text-align: center;
    width: 100%;
    /* width: 100%;
    max-width: 300px; */
    box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.1);
    /* transition: transform 0.3s ease-in-out; */
      justify-content: center;
  background-position: top;
  background-size:136%;
  background-repeat: repeat;
  z-index: 100;
  /* max-height: 379px; */
  flex-direction: column;
  
  }
.card::after{
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: top;
  background-size:cover;
  background-repeat: repeat;
  content: "";
  top: 0;
  z-index: -1;
  background-image: url("/src/assets/Home Page/polygon.png");

  left: 0;
opacity: .3;
   
     /* background: #40394f6e; */
  }
  
  /* .card:hover {
    transform: translateY(-5px);
  } */
  
  .image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    /* border: 3px solid #ff7e5f; */
  }
  
  .role {
    font-weight: 500;
    /* margin: 15px 0; */
    font-size: 14px;
    width: 70%;
    margin: auto;
    border-radius: 16px;
    padding: 6px 12px;
    margin-bottom: 24px;
  }

  .name{
    margin-bottom: 8px;
  }
  
  .description {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
    font-weight: 400;
  }
  
  .replacedRole {
    font-size: 12px;
    /* color: #aaa; */
    margin-bottom: 16px;
  }
  
  .details {
    display: flex;
    gap: 8px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  
  .detail {
    /* background: #444; */
    border: 1px solid #FFFFFF;
    padding: 8px 18px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    /* width: 40%; */
  }
  
  .tryNow {
    /* background: linear-gradient(to right, #ff7e5f, #ff5e99); */
    background: linear-gradient(90deg, #FF7031 0%, #9C54FF 100%);
    color: white;
    padding: 15px 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: 0.3s ease;
    width: 80%;
  }
  
  .tryNow:hover {
    opacity: 0.8;
  }
  
  .loadMore {
    background: #ff6347;
    color: white;
    padding: 12px 24px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin-top: 40px;
    transition: 0.3s ease;
  }
  
  .loadMore:hover {
    opacity: 0.8;
  }
  
  .roleTitle,.replacedRole,.description{
    /* margin: 0px; */
    /* align-items: left; */
    text-align: left;
  }

  .roleTitle{
    margin-bottom: -10px;
  }

  
  @media (max-width: 768px) {

    .cardsContainer {
      grid-template-columns:repeat(2,1fr);
    }
  }

  @media (max-width: 430px) {
    .container{
      padding: 20px;
    }
    .cardsContainer {
      /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
      grid-template-columns: repeat(1,1fr);
    }
    .tabs {
      flex-direction: column;
      align-items: center;
    }
    
    .tab,.activeTab{
      width: 100%;
  }
  }
  
 
  