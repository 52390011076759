.voiceContainer {
    text-align: center;
    /* margin-top: 50px; */
    width: 80%;
    padding: 1rem;
    position: relative;
  }
  .voiceTrigger{
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;

  }
  .outlineContainer{
    border-radius: .5rem;
    padding: 0.7rem 2rem;
    /* border: 2px solid white; */
    display: flex;
    align-items: center;
    gap: .8rem;
    width: 80%;
    justify-content: center;

    background-color: #545454;
    text-align: center;
    color: white;
    scrollbar-width: none;
    /* cursor: pointer; */
    
    font-weight: 600;
  }
   
  .playingIndicator {
    text-align: center;
    padding: 20px;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.7);
    color: white;
    border-radius: 10px;
    margin-top: 20px;
    animation: pulse 1.5s infinite;
  }
   
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  .micButton {
    position: relative;
    display: inline-flex
;
    align-items: center;
    background: transparent;
    /* margin-top: 4rem; */
    border: none;
    /* padding: 1rem; */
    /* border: #f8f8f8 2px solid; */
    justify-content: center;
    width: 130px;
    height: 130px;
    /* background-color: #007bff; */
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;
  }
  .hover {
    position: absolute;
    display: inline-flex;
    align-items: center;
    /* margin-top: 4rem; */
    top: 6px;
    justify-content: center;
    width: 120px;
    height: 120px;
    background-color: rgb(250 250 250 / 27%);
    /* background-color: #007bff; */
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;
  }
  .icon{

  }
   
  .micButton.listening {
    transform: scale(1.1);
  }
   
  .micIcon {
    font-size: 2rem;
    color: white;
    z-index: 10;
  }
   
  .waveContainer {
    position: absolute;
    top: -3px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
   
  .wave {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    animation: wave 1.5s infinite;
  }
   
  .wave:nth-child(2) {
    animation-delay: 0.5s;
  }
   
  .wave:nth-child(3) {
    animation-delay: 1s;
  }
   
  @keyframes wave {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }
   
  .recognizedText {
    /* margin-top: 88px; */
    font-size: 1.2rem;
    text-align: center;
    /* min-height: 100px; */
    color: #f8f8f8;
  }
  .pulseWaveContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3px;
    margin-top: 20px;
    height: 100px; /* Adjust the container height */
  }
   
  .pulseBar {
    width: 5px; /* Width of each pulse bar */
    background-color: #ffffff;
    opacity: 0.7;
    height: 20px; /* Initial height of each bar */
    animation: pulse 1.5s infinite ease-in-out;
  }
   
  .pulseBar:nth-child(1) {
    animation-delay: 0s;
  }
   
  .pulseBar:nth-child(2) {
    animation-delay: 0.2s;
  }
   
  .pulseBar:nth-child(3) {
    animation-delay: 0.4s;
  }
  .pulseBar:nth-child(4) {
    animation-delay: 0.6s;
  }
   
  .pulseBar:nth-child(5) {
    animation-delay: 0.8s;
  }
   
  .pulseBar:nth-child(6) {
    animation-delay: 1s;
  }
  .pulseBar:nth-child(7) {
    animation-delay: 1.2s;
  }
  .pulseBar:nth-child(8) {
    animation-delay: 1.4s;
  }
   
  @keyframes pulse {
    0% {
      transform: scaleY(1); /* Initial scale */
      opacity: 0.7;
    }
    50% {
      transform: scaleY(3); /* Pulse effect: bar grows taller */
      opacity: 1;
    }
    100% {
      transform: scaleY(1); /* Bar returns to initial size */
      opacity: 0.7;
    }
  }
  .pulseLoadContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 3px;
    margin-top: 20px;
    height: 100px; /* Adjust the container height */
  }
   
  .pulseLoadBar {
    width: 5px;
    background-color: #ffffff;
    opacity: 0.7;
    height: 2px;
    border-radius: 50%;
    animation: voice_pulse__ttfUG 1.5s infinite ease-in-out;
  }
   
  .pulseLoadBar:nth-child(1) {
    animation-delay: 0s;
  }
   
  .pulseLoadBar:nth-child(2) {
    animation-delay: 0.2s;
  }
   
  .pulseLoadBar:nth-child(3) {
    animation-delay: 0.4s;
  }
  .pulseLoadBar:nth-child(4) {
    animation-delay: 0.6s;
  }
   
  .pulseLoadBar:nth-child(5) {
    animation-delay: 0.8s;
  }
   
  .pulseLoadBar:nth-child(6) {
    animation-delay: 1s;
  }
  .pulseLoadBar:nth-child(7) {
    animation-delay: 1.2s;
  }
  .pulseLoadBar:nth-child(8) {
    animation-delay: 1.4s;
  }
  /* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#ffffff 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}
   
  @keyframes pulseLoad {
    0% {
      transform: scaleY(1); /* Initial scale */
      opacity: 0.7;
    }
    50% {
      transform: scaleY(3); /* Pulse effect: bar grows taller */
      opacity: 1;
    }
    100% {
      transform: scaleY(1); /* Bar returns to initial size */
      opacity: 0.7;
    }
  }
  @media only screen and (max-width:500px) {

  .outlineContainer{
    /* overflow-y: scroll;
    height: 6rem; */
  }
  }
  .chatIcon{
    position: absolute;
    top: -10px;
    z-index: -1;
    margin: auto;
  }
  .chatIconContainer{
    position: relative;
    display: flex;
    justify-content: center;
  }