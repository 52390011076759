.container {
    text-align: center;
    /* background-color: #2A2A2A; */
    color: #fff;
  }

  .main{
    padding: 50px 20px;
  }
  
  .header {
    font-size: 2.5rem;
    margin-bottom: -10px;
    width: 55%;
    margin: auto;
  }
  
  .smallMessage {
    font-size: 1rem;
    /* color: #bbb; */
  }
  
  .bigMessage {
    font-size: 1.2rem;
    width: 85%;
    /* max-width: 800px; */
    margin: 60px auto;
    line-height: 1.5;
  }
  
  .founderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    /* border-radius: 10px; */
    width: 90%;
    margin: auto;
    gap: 3rem;
  }
  
  .founderImage {
    width: 250px;
    height: 400px;
    border-radius: 8px;
    object-fit: cover;
    background: #ccc;
  }
  
  .founderDetails {
    flex: 1;
    /* padding-left: 20px; */
    text-align: left;
  }
  
  .founderDetails h2 {
    font-size: 1.8rem;
  }
  
  .founderDetails h3 {
    font-size: 1.2rem;
    /* color: #ff9800; */
    margin: -20px 0px;
    padding: 0px;
  }
  .expand{
line-height: 1.5rem;
margin-bottom: .3rem;
  }
  .readMore {
    background: none;
    border: none;
    color: #FF6B6B;
    cursor: pointer;
    font-size: 1rem;
    padding: 0px;
    margin-bottom: 20px;
    /* margin-top: 5px; */
  }
  
  .experienceBox {
    /* margin-top: 20px; */
    padding: 20px;
    border: 1px solid #FFFFFF;
    border-radius: 16px;
    /* background: #1c1c1c; */
    /* width: 70%; */
  }

  .experienceBox ol {
    list-style-type: disc; /* Circular bullet points */
    padding-left: 20px; /* Adds spacing from the left */
  }
  
  .experienceBox li {
    margin: 5px 0;
  }  
  
  .experienceBox h4 {
    margin-bottom: 10px;
  }
  
  .experienceBox ul {
    list-style: none;
    padding: 0;
  }
  
  .experienceBox li {
    margin: 5px 0;
  }
  
  .footerSection {
    margin-top: 50px;
    /* margin-left: -20px; */
    /* margin-right: -20px; */
    /* padding: 30px; */
    /* background: linear-gradient(135deg, #ff9800, #ff5722); */
    /* border-radius: 10px; */
    background: url("../../assets/linewhite-background.png") no-repeat center center/cover;
    background-color: #3c3c3c;
    color: #fff;
    margin: 5rem -5.5rem;
  }

  .together{
    width: 80%;
    margin: auto;
    padding: 5rem 0;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .contactButton {
    background: #fff;
    color: #ff5722;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .centre{
    text-align: center;
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .founderContainer {
      flex-direction: column;
      text-align: center;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  
    .founderImage {
      margin-bottom: 15px;
    }
  
    .founderDetails {
      padding-left: 0;
    }
  
    .header {
      font-size: 1.5rem;
      width: 90%;
      /* margin-bottom: 0px; */
    }
  
    .bigMessage,.together {
      font-size: 1rem;
    }
    .bigMessage{
      font-size: .8rem;
    }

    .bigMessage{
        width: 95%;
        /* margin-top: 2rem; */
        margin: 0 .8rem;
    }
    
    .footerSection{
        margin: 0rem -3rem;
    }
    .expand{
      font-size: .9rem;
    }
    .main{
      padding: 20px ;
    }
  }
  