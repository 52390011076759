.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pos-abs{
  position: absolute;
  /* top: 7%; */
}
.grid-5{
  background-color: #20202042;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(5,1fr);
}
.media-grid-item{
  display: flex
  ;
  margin: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}
.categories-container2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 2rem;
  width: 80%;
}
.flex-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-column-end {
  display: flex;
  /* align-items: center; */
  justify-content: end;
  flex-direction: column;
}
.flex-center{
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.flex-end{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 2rem;
}
.wrap{
    flex-wrap: wrap;
}
.flex-column-str {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 3rem;
    flex-direction: column;
  }
.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.m-auto{
    margin: auto;
}
.m-l-3{
    margin-left: 1rem;
}
.m-t-3{
    margin-top: 1rem;
}
.m-t-4{
  margin-top: 3rem;
}
.m-t-5{
  margin-top: 5rem;
}
.m-b-5{
  margin-bottom: 5rem;
}
.m-b-3{
    margin-bottom: 1rem;
}
.p-2{
  padding: 1rem;
}
.p-t-1{
    padding-top: .5rem;
}
.p-b-1{
    padding-bottom: .5rem;
}
.p-t-3{
    padding-top: 1.5rem;
}
.p-r-3{
  padding-right: 1.5rem;
}
.p-t-3{
    padding-top: 1.5rem;
}
.p-r-3{
  padding-right: 1.5rem;
}
.p-l-3{
  padding-left: 1.5rem;
}
.p-r-6{
  padding-right: 3rem;
}
.p-l-6{
  padding-left: 3rem;
}
.p-b-3{
    padding-bottom: 1.5rem;
}
.center{
    text-align: center;
}
.right{
  text-align: right;
}
.left{
  text-align: left;
}
.inpt-box{
  background-color: #F3F3F3;
  border-radius: .5rem;
  margin-top: .4rem;
  /* border: none; */
  padding-left: .5rem;
  height: 3rem;
  border: none;
  width: 100%;

}
/* .inpt-box:focus{
  border:rgba(245, 52, 87, 1) 35%, 1px solid;
  border: black solid 1px;
  outline: none;
} */

.flex-gap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* justify-content: flex-between; */
}
.head-md {
  font-size: 1.5rem;
  font-weight: 600;
}
.head-md-lg {
  font-size: 2rem;
  font-weight: 600;
}
.head-md-sm{
  font-size: 1.2rem;
  font-weight: 600;
}
.head-sm {
    font-size: 1rem;
    font-weight: 400;
    
  }
  .head-xsm {
    font-size: .9rem;
    font-weight: 600;
    
  }
.head-lg {
  font-size: 2.7rem;
  font-weight: 600;
}
.head-xlg{
  font-size: 2.7rem;
}
.font-w-4{
    font-weight: 400;
}

.primary-color {
  color: white;
}
.primary-dark-color {
  color: #06197B;
}
.secondary-color{
  color:#212121;
}
.secondary-light-color{
  color:#6D6D6D;
}
.gradiant-button {
  /* background: rgb(255, 114, 32);
  background: linear-gradient(
    90deg,
    rgba(255, 114, 32, 1) 0%,
    rgba(245, 52, 87, 1) 35%,
    rgba(190, 16, 179, 1) 100%
  ); */
  background: linear-gradient(90deg, #FF7031 0%, #9C54FF 100%);

}
.gradiant-pink-col {
  background: rgb(255, 114, 32);
  background: linear-gradient(90.16deg, #FF7031 16.76%, #A055F6 85.15%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradiant-blue-col {
  background: linear-gradient(234.54deg, #354C8C -6.38%, #8884AD 74.78%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradiant-light-blue{
  background: linear-gradient(94.19deg, #524B7D 1.06%, #2A498C 114.35%);


  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.gradiant-blue {
  background: radial-gradient(50% 50% at 50% 48%, #0a1f85 0%, #060d3d 100%);
  min-height: 49rem;
}
.feedback-container {
  position: relative;
  display: inline-block;
  padding:1rem 2rem ;
  background: linear-gradient(270deg, #010B3E 0%, #271B3B 100%);
   font-size: .7rem;
   font-weight: 400;
  color: #FFFFFF;
  /* font-size: 1.5rem; */
  /* background: #000; */
  z-index: 1;
}
.feedback-container-border {
  content: "";
  position: absolute;
  /* top: -3px; */
  /* background: rgb(255, 114, 32); */
  top: 0;
  left: -3px;
  /* right: -3px;
  bottom: -3px; */
  background: rgb(253,104,41);
  background: linear-gradient(185deg, rgba(253,104,41,1) 24%, rgba(244,52,88,1) 58%, rgba(200,22,163,1) 82%);
  z-index: -1;
  width: 5px;
  height: 100%;
  border-radius: 10px; /* Optional for rounded corners */
}

.req-button {
  border-radius: 1.8rem;
  padding: .9rem 2.5rem;
  border: none;
  font-weight: 600;
}
.req-button2 {
  border-radius: 1.2rem;
  padding: 0.9rem 1.7rem;
  border: none;
  font-weight: 600;
}
.outline-button{
  border-radius: .5rem;
  padding: 0.7rem 2rem;
  /* border: 2px solid white; */
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .8rem;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-weight: 600;
}
.white-button{
  border-radius: .5rem;
  padding: 0.7rem 2rem;
  /* border: 2px solid white; */
  display: flex;
  min-width: fit-content;
  border: none;
  align-items: center;
  gap: .8rem;
  background-color: white;
  color: rgb(35, 35, 35);
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}
.white-button-contact{
  border-radius: .5rem;
  padding: 1rem 2.5rem;
  /* border: 2px solid white; */
  display: flex;
  border: none;
  font-size: 1.2rem;
  align-items: center;
  gap: .8rem;
  background-color: white;
  color: rgb(35, 35, 35);
  cursor: pointer;
  font-weight: 600;
}
.white-button2{
  border-radius: 2rem;
  padding: .9rem 2rem;
  /* border: 2px solid white; */
  display: flex;
  border: none;
  align-items: center;
  gap: .8rem;
  background-color: white;
  color: rgb(35, 35, 35);
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}
.white-button3{
  border-radius: 3rem;
  padding: .9rem 2rem;
  /* border: 2px solid white; */
  display: flex;
  border: none;
  align-items: center;
  gap: .8rem;
  background-color: white;
  color: rgb(35, 35, 35);
  cursor: pointer;
  font-weight: 600;
  font-size: 1.5rem;
}
.outlineContainer{
  border-radius: .5rem;
  padding: 0.7rem 2rem;
  border: 2px solid white;
  display: flex;
  align-items: center;
  gap: .8rem;
  background-color: transparent;
  color: white;
  /* cursor: pointer; */
  font-weight: 600;
}
.submit-button {
  border-radius: 1.5rem;
  padding: 1rem 3rem;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
}
.form-container{
  /* border:2px white solid ; */
  padding:4rem;
  border-radius: .5rem;
}
.form-submit-button{
  border-radius: 1.8rem;
  padding: 1.2rem 2.5rem;
  border: none;
  font-weight: 600;
  background: linear-gradient(94.16deg, #FF7220 1.04%, #F53457 53.49%, #BE10B3 99.19%);
 color: white;
 width: 60%;
 font-size: 1rem;
 margin: auto;
}
.w-60 {
  width: 60%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-70 {
  width: 70%;
}
.w-35 {
  width: 35%;
}
.w-40 {
    width: 40%;
  }
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.w-100 {
  width: 100%;
}
.h-100{
  height:100%;
}
.w-50 {
  width: 50%;
}
.border-rds-1{
  border-radius: 1rem;
}
.navbar {
  padding: 1.5rem 2.8rem;
  position: sticky;
  /* background-color: #010B3E; */
  z-index: 10;
  top: 0;
}
.subNavbar{
  background: linear-gradient(111.19deg, rgba(217, 30, 158, 0.4) 9.25%, rgba(115, 115, 115, 0.1) 87.76%);
  border-radius: 1rem;
  padding: .5rem 1.5rem;
}
.tab{
  color: #ffffffcd;
  border-radius: .5rem;
  padding:.4rem .9rem;
  ;
}
.tab:hover{
  color: #ffffff;
  -webkit-text-decoration: underline 3px;
  /* text-decoration: underline 3px; */
  font-weight: 600;
  border: 1px solid white;
  /* text-underline-offset: 8px; */
  border-radius: .5rem;
  padding: .3rem .8rem;

}
.active-tab{
  color: #ffffff;
  -webkit-text-decoration: underline 3px;
  /* text-decoration: underline 3px; */
  font-weight: 600;
  border: 1px solid white;
  /* text-underline-offset: 8px; */
  border-radius: .5rem;
  padding: .3rem .8rem;
  
}
.footer{
  padding: 1.5rem;
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  color: white;
  background-color: #3C3C3C;

}
.mandat{
  color: red;
}
.pointer {
  cursor: pointer;
}
.cover {
  position: relative;
}
.cover-image {
  width: -webkit-fill-available;
  border-radius: .7rem;
}
.cover-home{
  background-image: url("/src/assets/Home Page/line-art-wavy-background-colorful 1.png");
  background-size: cover;
  overflow: hidden;
  background-position: center;
  position: relative;
  min-height: 530px;
  /* min-height: 493px; */
  display: flex
;
  gap: 2rem;
  align-items: center;
  flex-direction: column;
  justify-content: end;
}
.cover-featureslist{
  background: linear-gradient(111.19deg, rgba(71, 71, 71, 0.4) 9.25%, rgba(115, 115, 115, 0.1) 87.76%);
   padding: 1.5rem;
   border-radius: 1.5rem;
}
.cover-AiTemates{
 
  position: relative;
  /* opacity: .5; */

  background-repeat: no-repeat;
}

.cover-AiTemates::before{
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("/src/assets/Home Page/polygon-lines-background\ 3.png");
  background-position: center;
  background-size:cover;
  content: "";
  top: 0;
  left: 0;
  /* background-color: #2e2e2e8a; */
  opacity: .2;
}
.cover-req-demo{
  background: linear-gradient(111.19deg, rgba(255, 112, 49, 0.3) 9.25%, rgba(115, 115, 115, 0.1) 87.76%);
  width: 90%;
  border-radius: .7rem;
  gap: 2rem;
  padding: 4rem 1rem;
  margin: auto;
}
.cover-outline{
  border: 1px solid white;
  border-radius: 1rem;
  padding: 3rem;
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

}
.cover-opex{
  background: linear-gradient(111.19deg, rgba(255, 112, 49, 0.3) 9.25%, rgba(115, 115, 115, 0.1) 87.76%);
  /* width: 100%; */
  border-radius: .7rem;
  gap: 2rem;
  padding: 4rem 3rem;
  margin: auto;
}

/* .cover-home::before{
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  background-color: #d3d3d38f;
} */
.cover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 6rem;
  gap: 1rem;
}
.list-background{
  background-color: #3C3C3C;
  border-radius: .9rem;
  padding: 1rem;
  
}

.brand-list {
  background: #48484857;
  width: 100%;
  gap: .5rem;
  display: flex;
  align-items: center;
  /* padding: 0rem 2rem; */
  gap: 20px;
  justify-content: center;
}
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  /* width: 100%; */
  background: #48484857;

}
 
.marquee {
  display: flex;
  /* gap: .5rem; */
  align-items: center;
  animation: marquee-scroll 120s linear infinite;
}
 
.marquee span {
  /* padding: 10px 20px; */
  /* background: #007bff; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
}
 
@keyframes marquee-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.language-card{
    border-radius: 1.5rem;
    background-color: #F6F6F6;
    color:#060606 ;
    /* position: relative; */
    min-height: 350px;
    max-height: 379px;


}
.fade-content{
  color: #ffffff96;
  font-size: 1.1rem;
  word-spacing: 3px;
  line-height: 1.9rem;
  font-family: inter;

}
.fade-content2{
  color: #ffffffe8;
  font-size: .9rem;
  word-spacing: 3px;
  line-height: 1.9rem;
  font-family: inter;

}
.fade-content3{
  color: #ffffffe8;
  font-size: .9rem;
  word-spacing: 3px;
  line-height: 1.3rem;
  font-family: inter;

}
.line-h-2{
  line-height: 2;
}
.line-h-1{
  line-height: 1.5rem;
}
.bot-card{
  border-radius: 1.5rem;
  background: #40394f6e;

  color: white;
  /* position: relative; */
  display: flex
;
position: relative;
  gap: 1.5rem;
  align-items: center;
  padding: 1.7rem 1rem;
  /* min-height: 350px; */
  justify-content: center;
  background-position: top;
  background-size:136%;
  background-repeat: repeat;
  /* max-height: 379px; */
  flex-direction: column;
  background-image: url("/src/assets/Home Page/polygon.png");
}
.bot-card::before{
  position: absolute;
  width: 100%;
  height: 100%;
  
  background-position: top;
  background-size:cover;
  background-repeat: repeat;
  content: "";
  top: 0;
  left: 0;
  /* background-color: #2e2e2e8a; */
  opacity: .7;
}
.language-container{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem;
}
.star-image{
  position: absolute;
  top: 5%;
  left: 5%;
}

.language-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.language-card img:first-child{
    border-top-left-radius: 1.5rem;
    
    width: 100%;
    border-top-right-radius: 1.5rem;
}
.language-tab{
    position: absolute;
    bottom: 10%;
    color: white;
    font-size: .8rem;
    font-weight: 500;
    padding: .5rem 2rem;
    left: 0%;
    background-color:#4762A2 ;
}
.language-title{
    color: #2F4A8A;
    font-size: .9rem;
    font-weight: 500;
}
.language-spcl{
    background-color: #C5D6FF;
    border-radius: 1rem;
    font-size: .8rem;
    padding: .2rem .5rem;
}
.language-action{
    background-color: #ECECEC;
    padding: .5rem;
    font-size: .9rem;
    margin: 0rem .5rem;
    display: flex;
    gap: 15px;
}
.language-req-button{

    border-radius: .3rem;
    padding: .5rem 1rem;
    background-color: #4762A2;
    color: white;
    border: none;
    margin: auto;
    
}
.info-banner{
    position: relative;
    background-color: #863bbfa1;
    border-radius: 1.2rem;
    height: 100%;

}
.video-banner{
    position: relative;
    background-color: #4c4c4c9d;
    border-radius: 1.2rem;
    height: 100%;
}
.white-banner{
  background-color: white;
  color: #212121;
  text-align: center;
  letter-spacing: 1px;
  padding: 2.5rem .5rem;
}
.blue-banner{
  background-color: #010B3E;
  /* color: #212121; */
  text-align: center;
  background-position: center;
  background-size: cover;
  letter-spacing: 1px;

  padding: 3.5rem .5rem;
  /* background: linear-gradient(190.7deg, #1E1F58 -48.27%, #000C47 79.24%); */

}
.feature-container{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 2rem;
}
.nasscom-img{
  width: 50%;
  display: block;
  margin: auto;
}
.business-img{
  /* width: 70%; */
  display: block;
  margin: auto;
}
.cover-evolution{
  background: linear-gradient(111.19deg, rgba(71, 71, 71, 0.4) 9.25%, rgba(115, 115, 115, 0.1) 87.76%);
 border-radius: 1rem;
 padding: 3rem;
}
.sliderContianer{
  /* background: #3C3C3C; */
  width: 80%;
  margin: auto;
  background: linear-gradient(111.19deg, rgba(255, 112, 49, 0.3) 9.25%, rgba(115, 115, 115, 0.1) 87.76%);


  border-radius: .5rem;
  padding: 1rem;
}
.feature-black-card{
  border-radius: .7rem;
  position: relative;
  background-color: #3C3C3C;
  display: flex
;
  height: 100%;
  gap: 1.5rem;
  padding: 1rem;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  width: -webkit-fill-available;
  /* height: 24rem; */
  /* position: relative; */
  padding: 1rem 1.5rem;
}
.feature-card{
  border-radius: 1.2rem;
  position: relative;
  background-color: #3C3C3C;
  display: flex;
  min-height: 202px;


;
/* height: 80%; */
  gap: 1.5rem;
  padding: 1rem;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  /* height: 24rem; */
  /* position: relative; */
  padding: 2rem 1.5rem;

}
.feature-card-flip{
  border-radius: 1.2rem;
  position: relative;
  background-color: #3C3C3C;
  /* display: flex
; */
  min-height: 202px;
  /* height: 80%; */
  gap: 1.5rem;
  padding: 1rem;
  align-items: start;
  /* flex-direction: column; */
  justify-content: center;
  color: #ffffff;
  /* height: 24rem; */
  /* position: relative; */
  text-align: left;
  padding: 2rem 1.5rem;
}
.feature-card-gradiant{
  border-radius: 1.2rem;
  position: relative;
  background: linear-gradient(97.38deg, #FF7031 -15.49%, #9A54FF 90.3%);


  display: flex

;
/* height: 80%; */
  gap: 1.5rem;
  padding: 1rem;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  /* height: 24rem; */
  /* position: relative; */
  padding: 2rem 1.5rem;
}
.feature-grid{
  display: grid;
  gap: 1.6rem;
  grid-template-columns: repeat(3,1fr);
}


.feature-cover{
  position: absolute;
  /* top: 10%; */

}
.feature-content{
  /* position:absolute; */
  /* bottom: 20%; */
  /* padding: 1rem 0rem; */


}
.secure-grid{
  display: grid
  ;
      width: auto;
      margin: 0rem 4rem;
      grid-template-columns: repeat(2, 1fr);
      gap: 28px;
}
.key-grid{
  display: grid
  ;
      /* width: auto; */
      /* margin: 0rem 4rem; */
      grid-template-columns: repeat(2, 1fr);
      gap: 1.2rem;
}
.secure-img{
  width: 121px;
  position: relative;
}
.secure-title{
  position: absolute;
  top: 40%;
  left: 25%;
  font-size: 1.3rem;
  font-weight: 500;

}
.outcome-banner{
  background-color: #010B3E;
  /* color: #212121; */
  text-align: left;
  letter-spacing: 1px;
  color: white;
  border-radius: 1rem;

  padding: 3.5rem;
  /* background: linear-gradient(190.7deg, #1E1F58 -48.27%, #000C47 79.24%); */
}
.about-cover{
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.value.cover{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.value-circle{
  width: 12px;
  height: 12px;
  min-width: 12px;
  /* border: none; */
  border-radius: 50%;
  display: flex
;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  border: 4px solid white;
}
.news-img{
  /* border-radius: 2.3rem; */
  border-radius: 1.2rem;
  width: 100%;
}
.join-button{
  border-radius: 2rem;
  border: none;
  padding: 1rem 5rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: white;
  

}
.value-img{
  position: absolute;
  width:-webkit-fill-available;
  bottom: -30%;
}
.coverHeader{
  position: absolute;
  top: 0;
  
}

.menu{
color:white ;
/* margin-left: 1rem; */
padding: 1rem 1rem 1rem 2rem;



}
.mainMenu{
  color:white ;
/* margin-left: 1rem; */
padding: 1rem 1rem 1rem 2rem;
}
.menuActive{
  background: linear-gradient(90deg, #FF7031 0%, #9C54FF 100%);
  color: white;
  background: linear-gradient(90deg, #FF7031 0%, #9C54FF 100%);
/* margin-left: 1rem; */

  padding: 1rem 1rem 1rem 2rem;
}
.menu:hover{
  background: linear-gradient(90deg, #FF7031 0%, #9C54FF 100%);
  color: white;
}
.menus{
  border-radius: 1rem 0rem 0rem 1rem;
  /* height: 50vh !important; */
  height: max-content !important;
  top: 7% !important;
  padding-bottom: 1rem;
  background-color: #252525 !important;

}
.contact-form{
  background: linear-gradient(95.96deg, #F36C49 0.74%, #9F54F9 89.8%);
  
}
.cardSelector{
  background: linear-gradient(111.19deg, rgba(71, 71, 71, 0.4) 9.25%, rgba(115, 115, 115, 0.1) 87.76%);


}
.join-us{
  background: linear-gradient(97.38deg, #FF7031 -15.49%, #9A54FF 90.3%);



}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  width: max-content;
  position: absolute;
  background-color: #3c3c3c;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  font-family: "Bebas Neue", sans-serif;
  top: 32px;
  z-index: 1;
}
.dropdown-item{
  color: #ffffffad;
  font-size: .9rem;
  padding: .5rem;
  cursor: pointer;

}
.dropdown-item-active{
  color: #ffffff;
  font-size: .9rem;
  padding: .5rem;
  cursor: pointer;


}
.dropdown-item:hover{
  color: #ffffff;
  font-size: .9rem;
  padding: .5rem;
  font-weight: 500;
  cursor: pointer;
}
.dropdown-item-active:hover{
  color: #ffffff;
  font-weight: 500;
  font-size: .9rem;
  /* font-style: underline 2px; */
  padding: .5rem;
  cursor: pointer;
  }

.dropdown:hover .dropdown-content {
  display: block;
}
.outline-tab-container{
 border-radius: .4rem;
 display: flex;
 font-weight: 500;
 gap: .6rem;
 border: 1px solid white;
 padding: .5rem;

}
.outline-active-tab{
  background-color: white;
  color: #2E2E2E;
  padding: 1rem 2rem;

  border-radius: .4rem;

  cursor: pointer;

}

.outline-tab{
  background-color:transparent;
  color: white;
  cursor: pointer;
  padding: 1rem 2rem;
}

.tab-comp{
  background-color: #3C3C3C;
  padding: 1.5rem;
  border-radius: 1rem;
}
.outline-container{
  border-radius: .5rem;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  padding: 1rem;
}
.gradiant-tabs{

  display: flex
  ;
      justify-content: center;
      gap: 1rem;
      background: linear-gradient(111.19deg, rgba(120, 30, 217, 0.4) 9.25%, rgba(115, 115, 115, 0.1) 87.76%);
      padding: 1rem;
      margin: auto;
      width: fit-content;
      border-radius: 1rem;

}
.subscribe{
  background: linear-gradient(95.96deg, #F36C49 0.74%, #9F54F9 89.8%);
 width: 95%;
 margin: auto;
 padding: 3rem 0rem;
 border-radius: 1rem;


}
.subscribe-mail-container{
  min-height: 2rem;
  flex-wrap: wrap;

}
.subscribe-inpt{
 
  padding: 1rem;
  border: none;
  height: 2rem;
  border-radius: .7rem;
  width: 400px;

}
.subscribe-inpt:placeholder-shown{
  font-size: large;

}
.subscribe-button{
  background: linear-gradient(94.16deg, #FF7220 1.04%, #F53457 53.49%, #BE10B3 99.19%);
  border-radius: .5rem;
  padding: .9rem 2.5rem;
  color: white;
  border: none;
  height: 4rem;
  font-size: 1rem;
  
  font-weight: 700;
}

.gap-10{
  gap: 10%;
}
.gap-5{
  gap: 5%;
}
.gap-20{
  gap: 20%;
}
.gap-1{
  gap: 1rem;
}



.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
.modal{
 width: 26%;
 position: relative;
  margin: auto;
  height: fit-content;
  border-radius: 1.5rem;
  /* background: #40394f6e; */
 z-index: 30;
  color: white;
  /* position: relative; */
  display: flex;
  gap: 1.5rem;
  background-color: #3C3C3C;
  align-items: center;
  /* padding: 1.7rem 1rem; */
  /* min-height: 350px; */
}
.RevolutionModal{
  width: 80%;
  position: relative;
   margin: auto;
   scrollbar-width: thin;
   scrollbar-color: rgb(78, 77, 77) transparent;
   height: 87vh;
   border-radius: 1.5rem;
   /* background: #40394f6e; */
  z-index: 30;
   color: white;
   /* position: relative; */
   /* display: flex; */
   gap: 1.5rem;
   padding: 2.5rem;
   background-color: #3C3C3C;
   align-items: center;
   /* padding: 1.7rem 1rem; */
   overflow: auto;
   /* min-height: 350px; */
}
.modalCrossButton{
  z-index: 10000;
  right: 10px;
  display: flex
;
  position: sticky;
  justify-content: end;
  /* width: fit-content; */
  top: 10px;
  /* display: block; */
  cursor: pointer;
}

.overlay{
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  inset: 0;
  z-index: 100000;
  background-color:#21212182 ;

}

/* --------------------tablet view ---------------------- */

@media only screen and (max-width:1050px) {
  .language-container{
    grid-template-columns: repeat(3,1fr);
  }
  .modal{
    width: 40%;
  }

  
}

@media only screen and (max-width:700px) {
  .language-container{
    /* grid-template-columns: repeat(2,1fr); */
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: .7rem;
  }
  
  .inpt-box{
    width: 95%;
  }
  .subscribe-inpt{
    width: 90%;
  }
  .subscribe-button{
    padding: .5rem 1rem;
  }

  .cover-content{
    margin-top: 3rem;
    /* height: 100%; */
  }
  .language-card{
    min-height: auto;
    max-height: 500px;
  }
  .feature-container{
    grid-template-columns: repeat(1,1fr);
    margin: 0rem 1rem;
    

  }
  .key-grid{
    grid-template-columns: repeat(1,1fr);

    

  }
  .feature-grid{
    grid-template-columns: repeat(2,1fr);
    width:100%;
  }
  .form-container{
    border: none;
  }
  .categories-container2{
    grid-template-columns: repeat(1,1fr);

  }
.form-container{
  padding: 1rem;
}
  
  .head-md{
    font-size: 1rem;
  }
  .head-md-sm{
    font-size: .9rem;
  }
  .feedback-container{
    width: 100% !important;
  }
  .flexMobileWrap{
    flex-wrap: wrap;
  }
  .block{
    display: block;
  }
  h1{
    font-size: 1.2rem;
  }
  h2{
    font-size: 1.1rem;
  }
  h3{
    font-size: 1rem;
  }
  .gradiant-blue{
    /* min-height: 37rem; */
  }
  .white-button2{
    padding: .7rem 2rem;
  }
  .mobileWithNone{
    width: auto;
  }
  .footer{
    padding: .5rem .8rem;
  }
  .head-lg{
    font-size: 1.5rem;
  }
  .outline-button{
    padding: 0.6rem 1rem;
  }
  .submit-button{
    padding: 0.8rem 2rem;
  }
  .req-button {
    padding: 0.5rem 1rem;
    font-size: .9rem;

  }
}
@media  screen and (max-width:1024px)and (min-width:700px){
  .feature-container{
    grid-template-columns: repeat(2,1fr);
  }
  .head-lg{
    font-size: 1.5rem;
  }
  .head-sm{
    font-size: .9rem;

  }
  .key-grid{
    grid-template-columns: repeat(1,1fr);
  }
  .language-container{
    grid-template-columns: repeat(2,1fr);
  }
  .language-card{
    max-height: 450px;
  }
}
@media only screen and (max-width:499px) {
 
  .cover-opex{
    padding: 2rem;
  }
  .modal{
    width: 80%;
    /* margin:initial */
  }
  .cover-outline{
    padding: 1rem;
    text-align: center;
    width: 95%;
  }
  .RevolutionModal{
    padding: 1rem;
    width: 90%;
    margin: auto;
  }
 .flex-center{
  gap: .5rem;
 }
 .head-md-lg{
  font-size: 1.375rem;
 }
 .head-xsm{
  font-size: 0.725rem;
  font-weight: 400;
 }
 .head-sm{
  font-size: 0.9rem;
 }
 .head-xlg{
  font-size: 1.8rem;
  font-weight: 600;
  
 }
 .white-button-contact{
  margin: auto;
  width: 80%;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;

 }
 .fade-content{
  font-size: .8rem;
  line-height: 1.5;
 }
 .coverHeader{
  top: 10px;
 }
 .white-button3{
  padding: .8rem;
 }
 .fade-content2{
  font-size: .7rem;
 }
 .white-button{
  padding: 0.3rem 1rem;
 }
}