.safeAIContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px;
    /* background-color: #111; */
    color: white;
  }
  
  /* ------------------- */
  /* 🟢 Top Section */
  /* ------------------- */
  .topSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    /* width: 90%; */
    margin: auto;
  }
  
  /* Left Side - Big Text */
  .leftContent {
    flex: 1;
  }
  
  .leftContent p {
    /* font-size: 18px; */
    margin-top: 10px;
  }
  
  /* Right Side - Image & Overlay */
  .rightImage {
    position: relative;
    flex: 1;
  }
  
  .image {
    width: 80%;
    height: auto;
    border-radius: 10px;
  }
  
  .overlayText {
    position: absolute;
    bottom: 10px;
    /* left: 10px; */
    background: rgba(0, 0, 0, 0.6);
    /* padding: 10px; */
    border-radius: 5px;
    font-size: 14px;
    height: 97%;
    width: 79.5%;
    display: grid;
    align-items: flex-end;
  }

  .overlay_p{
    padding: 1.5rem;
    font-size: 1.3rem;
    font-weight: 700;
    /* width: 80%; */
  }

  .left_p{
    line-height: 1.8rem;
    font-size: 1.1rem;
  }
  
  /* ------------------- */
  /* 🔵 Bottom Section */
  /* ------------------- */
  .bottomSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }
  
  /* Left Side - Cards */
  .cardsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    flex: 1;
  }
  
  .card {
    /* background: linear-gradient(to right, #ff7e5f, #ff6b81); */
    background: linear-gradient(180deg, #E96A5C 0%, #D0628E 46%, #8E41E6 100%);
    padding: 4rem 2rem;
    border-radius: 8px;
    text-align: left;
    font-weight: bold;
    font-size: 1.2rem;
    max-width: 16rem;
    max-height: 6rem;
  }
.li{
line-height: 1.6rem;
font-size: 1rem;
text-align: left;
}
  .lefth2{
    font-size:2.5rem;
    margin-top: 0px;
  }
  
  /* Right Side - Small Text */
  .rightContent {
    flex: 1;
  }
  
  /* ------------------- */
  /* 📱 Responsive Design */
  /* ------------------- */
  @media (max-width: 768px) {
    .topSection,
    .bottomSection {
      flex-direction: column;
      /* text-align: center; */
    }

    .image,.overlayText{
      width: 100%;
    }
  
    .rightImage {
      width: 100%;
    }
  
    .cardsContainer {
      grid-template-columns: 1fr;
    }

    .lefth2,.left_p{
      text-align: center;
    }
    .lefth2{
      font-size: 1.5rem;
    }
  }

  @media (max-width:472px) {
    .safeAIContainer{
      padding: .6rem;
      gap: 3rem;
    }
    .card{
      max-width: 10rem;
      max-height: 6rem;
      padding: 3rem 2rem;
    }
    .cardsContainer{
      grid-template-columns: repeat(2,1fr);
    }
    .left_p{
      line-height: 1.2rem;
      font-size: 0.8rem;
    }
    .li{
      font-size: .9rem;
      margin-bottom: .8rem;
      line-height: 1.2rem;
    }

  }
  