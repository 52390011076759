body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: Inter !important;
  -webkit-font-smoothing: antialiased;
  background-color: #252525;
  color:#FFFFFF;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: rgb(78, 77, 77) transparent;
  /* max-width: 1480px; */
  min-height: 100vh;
  overflow-x: hidden ;
  width: 100%;
  /* padding-bottom: 1rem; */
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input:focus{
  border:rgba(245, 52, 87, 1) 35% 1px solid;
  outline: none;

}
input{
  font-family: Inter !important;

}
textarea{
  font-family: Inter !important;


}
button{
  cursor: pointer;
}
html{
  scroll-behavior: smooth;
}