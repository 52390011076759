.container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    /* background-color: #2A2A2A; */
  }
  
  .confirmation, .successContainer {
    background: #3C3C3C;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    width: 90%;
    max-width: 400px;
  }
  .successContainer {
    max-width: 500px;
  }
  
  .message{
font-size: 2rem;
  }

  .container h2 {
    color: #fff;
  }
  
  .container p {
    color:#fff;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 1rem;
  }
  
  .container button {
    padding: 12px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-size: .8rem;
    min-width: 8rem;
    font-weight: 600;
  }
  
  .stay {
    /* background: #ff8800; */
    background: linear-gradient(90deg, #FF7031 0%, #9C54FF 100%);
    color: white;
  }
  
  .unsubscribe {
    background: #fff;
    color: black;
  }
  
  .successContainer {
    background: #2a2a2a;
    /* width: 100%; */
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: -2rem;
  }
  
  .logo {
    font-size: 28px;
    font-weight: bold;
    /* color: #ff5500; */
    background: linear-gradient(89.72deg, #FE6B26 24.64%, #F53754 46.98%, #D218B4 78.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2rem;
  }
  
  .checkmark {
    font-size: 50px;
    color: #ff8800;
    margin: 20px 0;
  }
  
  .resubscribe {
    color: #ff8800;
    /* text-decoration: none; */
  }
  
  @media (max-width: 768px) {
    .confirmation, .successContainer {
      width: 95%;
      padding: 5px;
      padding-bottom: 2rem;
    }
    .container{
        width: 90%;
        margin: auto;
    }
    .message{
        font-size: 1.4rem;
    }
  }
  
  @media (max-width:360px) {
    .buttons{
        display: grid;
    }
  }